<template>
  <scroll-layout class="detail">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <ykc-detail :titles="['电站信息']" class="detail-box">
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form :label-position="'left'" label-width="88px" class="list-left">
            <ykc-detail-item :label="'电站名称：'">
              <span>{{ dealData(detailData.stationName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'电站总功率：'">
              <span>{{ dealData(detailData.totalPower) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="有序模式：">
              {{
                detailData.powerWorkType === 1
                  ? '先到先充'
                  : detailData.powerWorkType === 0
                  ? '功率均衡'
                  : '——'
              }}
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-table
      style="margin-top: 16px"
      ref="YkcTable"
      :data="tableData"
      :columns="tableColumns"
      title="有序控制"
      :operateButtons="[]"></ykc-table>
  </scroll-layout>
</template>
<script>
  import { orderlyPage } from '@/service/apis';

  export default {
    name: 'orderlyControlDetail',
    data() {
      return {
        detailData: {},
        tableData: [],
        tableColumns: [
          {
            label: '时段',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { powerStartPeriod, powerEndPeriod, isDefault } = row;
                if (isDefault === 1) {
                  return '剩余时段';
                }
                const parse = id => {
                  id = Number(id);
                  const index = id - 1;
                  const hour = Math.floor(index);
                  const name = `${`${hour}`.padStart(2, '0')}:00`;
                  return name;
                };
                return `${parse(powerStartPeriod)}~${parse(powerEndPeriod)}`;
              },
            },
          },
          {
            label: '允许总功率',
            scopedSlots: {
              default: ({ row: { powerNum } }) => `${powerNum}KW`,
            },
          },
        ],
      };
    },
    created() {
      const queryInfo = this.$route.query;
      this.detailData = queryInfo;
      this.getDetail();
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    methods: {
      /**
       * 详情数据
       */
      getDetail() {
        orderlyPage
          .orderlyControlDetail({ stationId: Number(this.$route.query.id) })
          .then(res => {
            this.tableData = res.allowRatedPowerList || [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      dealData(value) {
        if (value !== undefined && value !== null && value !== '') {
          return value;
        }
        return '——';
      },
    },
  };
</script>
